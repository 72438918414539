import { baseUri1, builderbaseUri, builderApiBaseUrl } from "src/config/config";
import { invokeApi } from "../../bl_libs/invokeApi";
export const pagesListingApi = async (data) => {
  const requestObj = {
    path: `api/sale_page/template_page_for_consultant`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const PageListingApi = async (data) => {
  const requestObj = {
    path: `api/sale_page/sale_page_for_consultant`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const FunnelListing = async (_id) => {
  const requestObj = {
    path: `api/page_group/get_page_group/` + _id,
    // path: `api/page_group/list_group_by_page_id/` + _id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const MakePageShareable = async (data) => {
  const requestObj = {
    path: `api/page_group/make_page_shareable`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const LinkedPageListingApi = async (data) => {
  const requestObj = {
    path: `api/sale_page/link_page_for_consultant/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGQyMGU2MTk5NmU3NDY1ZTk3MWZkZjAiLCJsb2dpbl9ieSI6ImNvbnN1bHRhbnRfdXNlciIsInNlY3JldF9hdXRoX2tleSI6IjFpOHdkczZkYmRueWQ2ZSZiZ2ViZWJiZSIsImlhdCI6MTY5MTU2NTA0OH0.kvAIUpPHtnya869OUqskZVOoHvl2h2GHHwmV_saoLys",
    },
  };
  return invokeApi(requestObj);
};
export const page_listing_api = async () => {
  const requestObj = {
    path: `api/sale_page/get_sale_page_list_for_consultant`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const link_listing_api = async () => {
  const requestObj = {
    path: `api/consultant/links_listing/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const project_configuration_api = async (id) => {
  const requestObj = {
    path: `api/project_configuration/get_project_configuration/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const email_domain_configuration_api = async (id) => {
  const requestObj = {
    path: `api/email_configuration/get_coach_email_configuration/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const import_template_data_api = async (id) => {
  const requestObj = {
    path: `app/import_template_data`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: id,
  };
  return invokeApi(requestObj);
};
export const templateListingApi = async (data) => {
  const requestObj = {
    uri: builderbaseUri,
    path: `api/template/list_template`,
    method: "POST",
    headers: {
      "x-sh-auth":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXRfYXV0aF9rZXkiOiIxaTh3ZHM2ZGJkbnlkNmUmYmdlYmViYmUiLCJpYXQiOjE2ODg5Njc4ODV9.9hGtIrHK26AR8yZ4V5o09xi9vB95NZI07jzlx5F5qUo",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _templates_group_list = async () => {
  const requestObj = {
    uri: builderbaseUri,
    path: `api/template_group/list_template_group_by_page_detail`,
    method: "GET",
    headers: {
      "x-sh-auth":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXRfYXV0aF9rZXkiOiIxaTh3ZHM2ZGJkbnlkNmUmYmdlYmViYmUiLCJpYXQiOjE2ODg5Njc4ODV9.9hGtIrHK26AR8yZ4V5o09xi9vB95NZI07jzlx5F5qUo",
    },
  };
  return invokeApi(requestObj);
};
export const _templates_category_list = async () => {
  const requestObj = {
    uri: builderbaseUri,
    path: `api/template_category/list_template_category`,
    method: "GET",
    headers: {
      "x-sh-auth":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXRfYXV0aF9rZXkiOiIxaTh3ZHM2ZGJkbnlkNmUmYmdlYmViYmUiLCJpYXQiOjE2ODg5Njc4ODV9.9hGtIrHK26AR8yZ4V5o09xi9vB95NZI07jzlx5F5qUo",
    },
  };
  return invokeApi(requestObj);
};
export const Category_ListingApi = async (data) => {
  const requestObj = {
    uri: builderbaseUri,
    path: `api/category/list_category`,
    method: "GET",
    headers: {
      "x-sh-auth":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXRfYXV0aF9rZXkiOiIxaTh3ZHM2ZGJkbnlkNmUmYmdlYmViYmUiLCJpYXQiOjE2ODg5Njc4ODV9.9hGtIrHK26AR8yZ4V5o09xi9vB95NZI07jzlx5F5qUo",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addWebsiteEvent = async (data) => {
  const requestObj = {
    path: `api/home_event/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editWebsiteEvent = async (data, id) => {
  const requestObj = {
    path: `api/home_event/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteWebsiteEvent = async (id) => {
  const requestObj = {
    path: `api/home_event/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteSalePageApi = async (slug, data) => {
  const requestObj = {
    path: `api/page/delete_page/${slug}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteLinkedPageApi = async (slug, id) => {
  const requestObj = {
    path: `api/sale_page/delete_link_page/${slug}/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const WebsiteEventDetail = async (id) => {
  const requestObj = {
    path: `/api/home_event/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addPageApi = async (data) => {
  const requestObj = {
    path: `api/sale_page/add/by_consultant`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddPageApi = async (data) => {
  const requestObj = {
    path: `api/page/add_page`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editPageApi = async (data, id) => {
  const requestObj = {
    path: `api/sale_page/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editLinkedPageApi = async (data, slug, id) => {
  const requestObj = {
    path: `api/sale_page/update_link_page/${slug}/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailPageApi = async (id) => {
  const requestObj = {
    path: `api/page/get_page_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const updatePageContentSettingApi = async (data, id) => {
  const requestObj = {
    path: `api/sale_page/update_sale_page_detail/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const websiteEventListApi = async (api_path) => {
  // let api_path = id;
  // if (id) {
  //   api_path = `/api/home_event/home_event_by_sale_page/${id}`;
  // }
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const addPageEventApi = async (data) => {
  const requestObj = {
    path: `api/home_event/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editPageEventApi = async (data, id) => {
  const requestObj = {
    path: `api/home_event/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const page_detail_by_id = async (id) => {
  const requestObj = {
    path: `api/sale_page/sale_page_detail_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const changePageAccessApi = async (data) => {
  const requestObj = {
    path: `api/sale_page/control_page_access`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _builder_auth = async (data) => {
  const requestObj = {
    uri: builderApiBaseUrl,
    path: `/api/app_api/wezily_auth`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _publish = async (data) => {
  const requestObj = {
    path: `api/page_group/publish_funnel`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const list_thanks_and_payement_page_for_selection_api = async () => {
  const requestObj = {
    path: `api/sale_page/list_thanks_and_payement_page/selection`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const add_page_group = async (data) => {
  const requestObj = {
    path: `api/page_group/add_page_group`,
    // path: `api/page_group`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detail_page_group = async (id) => {
  const requestObj = {
    path: `api/page/get_page_by_page_group_id/` + id,
    // path: `api/page_group/detail_group/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const edit_page_group = async (data) => {
  const requestObj = {
    path: `api/page_group/edit_page_group`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const edit_sale_page_title = async (data) => {
  // console.log(id, data, "apihitsalepagetitle");
  const requestObj = {
    path: `api/page/edit_page_title`,
    // path: `api/sale_page/update_sale_page_title/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const edit_sale_page_slug = async (data) => {
  const requestObj = {
    path: `api/page/edit_page_slug`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delete_Page_group_Api = async (id, data) => {
  const requestObj = {
    path: `api/page_group/delete_page_group/` + id,
    // path: `api/page_group/delete_page_group?id=` + id,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const make_default_Page_group_Api = async (data) => {
  const requestObj = {
    path: `api/page_group/update_default_group`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_page_listing_form_group = async (id) => {
  const requestObj = {
    path: `api/page_group/get_page_group/` + id,
    // path: `api/page_group/list_page_by_group_id/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _update_step_in_page_group = async (data) => {
  const requestObj = {
    path: `api/page_group/update_page_group_steps`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _change_page_order_in_page_group = async (data) => {
  const requestObj = {
    path: `api/page/change_page_order`,
    // path: `api/page_group/change_order_of_pages`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const publish_page_group = async (data) => {
  const requestObj = {
    path: `api/page/move_files_to_hosting_bucket`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const subscriber_against_funnel = async (id) => {
  const requestObj = {
    path: `api/page_group/list_subsciber_by_group_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const transaction_against_funnel = async (id) => {
  const requestObj = {
    path: `api/page_group/list_transaction_against_page_group/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const pages_against_coach = async (id) => {
  const requestObj = {
    path: `api/page/get_all_page_against_coach/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
//  Manage Page

export const update_page_meta_setting = async (data) => {
  const requestObj = {
    path: `api/page/update_meta_setting`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_mailchimp_page_setting = async (data) => {
  const requestObj = {
    path: `api/page/update_mailchimp`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_active_compaign_page_setting = async (data) => {
  const requestObj = {
    path: `api/page/update_tag_and_list`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_active_compaign_plan_setting = async (data) => {
  const requestObj = {
    path: `api/payment_plan/update_tag_and_list`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_mailchimp_plan_setting = async (data) => {
  const requestObj = {
    path: `api/payment_plan/update_mailchimp`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_page_social_sharing = async (data) => {
  const requestObj = {
    path: `api/page/update_social_setting`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const upload_image_on_S3 = async (data, token) => {
  const requestObj = {
    uri: builderApiBaseUrl,
    path: `/api/app_api/upload_image_on_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": token ? token : localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _save_user_design_html = (data, auth_key) => {
  // console.log(auth_key, "requestObjforbuilder");

  let requestObj = {
    uri: builderApiBaseUrl,
    path: "/api/project/user_template_html",
    method: "POST",
    headers: {
      "x-sh-key": auth_key ? auth_key : localStorage.getItem("token"),
    },
  };

  console.log(requestObj, "requestObjforbuilder");
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

// Manage Cookies

export const _manage_project_cookies = (data) => {
  let requestObj = {
    path: "api/project_configuration/update_cookies",
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
